<template>
  <h1>test</h1>
  <!-- <av-line :line-width="2" line-color="lime" :audio-src="src"></av-line> -->
</template>

<script>
// import src from "@/assets/audio/SoundHelix-Song-1.mp3";
export default {
  name: "Test",
  components: {},
  data() {
    return {
      // src: src,
    };
  },
  computed: {},
  methods: {},

  async created() {},
};
</script>
